import { createGlobalStyle } from 'styled-components/macro';
import Couture from '../../assets/fonts/Couture.woff2';
import styled from 'styled-components/macro';
import { down, up } from 'styled-breakpoints';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const ContentContainer = styled.div`
  padding-bottom: 200px;
  ${down('md')} {
    padding-bottom: 350px;
  }
`;

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Couture';
        src: local('Couture'), local('Couture'),
            url(${Couture}) format('woff2');
        font-weight: 300;
        font-style: normal;
    }

    html {
        height:100%;
        font-size: 17px;
        background-color: #00FFF0;
        margin: 0;

        ${up('xl')} {
            font-size: 18px;
        }
        ${up('xxl')} {
            font-size: 19px;
        }
    }

    body {
        overscroll-behavior-y: contain;
        height:100%;
        margin: 0;
        font-family: Couture, Helvetica, sans-serif;
        background-color: #00FFF0;
    }

    #root {
        height:100%;
        margin: 0;
        background-color: #00FFF0;
    }

    h5, h4 {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
`;
