import React, { useEffect, useState } from 'react';
import * as S from './Home.styles';
import BottomProtraitWebp from '../../assets/images/bottom_portrait.webp';
import TopProtraitWebp from '../../assets/images/top_portrait.webp';
import Projects from '../Projects/Projects';

function checkScrollDirectionIsUp(event: WheelEvent) {
  if (event.deltaY < 0) {
    return true;
  } else if (event.deltaY > 0) {
    return false;
  }
  return undefined;
}
function checkTouchMoveDirectionIsUp(event: TouchEvent, lastY: number) {
  const currentY = event.changedTouches[0].clientY;
  if (currentY > lastY) {
    return false;
  } else if (currentY < lastY) {
    return true;
  }
}

const Home: React.FC = () => {
  const [currentPercent, setCurrentPercent] = useState(0);
  const [touchY, setTouchY] = useState(0);

  useEffect(() => {
    const handleScroll = (ev: WheelEvent) => {
      if (checkScrollDirectionIsUp(ev) && currentPercent > 0) {
        setCurrentPercent(currentPercent - 1);
      } else if (!checkScrollDirectionIsUp(ev) && currentPercent < 100) {
        setCurrentPercent(currentPercent + 1);
      }
    };
    const handleTouchStart = (ev: TouchEvent) => {
      setTouchY(ev.touches[0].clientY);
    };
    const handleTouchMove = (ev: TouchEvent) => {
      if (checkTouchMoveDirectionIsUp(ev, touchY) && currentPercent > 0) {
        setCurrentPercent(currentPercent - 1);
      } else if (
        !checkTouchMoveDirectionIsUp(ev, touchY) &&
        currentPercent < 100
      ) {
        setCurrentPercent(currentPercent + 1);
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: true });
    window.addEventListener('touchstart', handleTouchStart, { passive: true });
    window.addEventListener('touchmove', handleTouchMove, { passive: true });

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [currentPercent, touchY]);

  return (
    <S.Wrapper>
      <Projects height={currentPercent} />
      <S.TopPart>
        <S.PortraitImgTop src={TopProtraitWebp} top={currentPercent} />
        <S.PortraitImgBottom src={BottomProtraitWebp} bottom={currentPercent} />
      </S.TopPart>
      <S.BottomPart opacity={currentPercent / 100}>
        <div>
          <S.Subtitle>CREATIVE</S.Subtitle>
          <S.Subtitle>DEVELOPER</S.Subtitle>
        </div>
        <S.Skills>
          <S.Skill>Web & Mobile Frontend</S.Skill>
          <S.Dot />
          <S.Skill>UI/UX Design</S.Skill>
        </S.Skills>
      </S.BottomPart>
    </S.Wrapper>
  );
};

export default Home;
