import React, { useState } from 'react';
import { useMousePosition } from '../../hooks/useMousePosition';
import FlurryScreen from '../../assets/images/flurry_screen.png';
import CTradeScreen from '../../assets/images/c-trade_screen.png';
import NexterScreen from '../../assets/images/nextertraining_screen.png';
import * as S from './Projects.styles';

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const MediaItem: React.FC<{
  url: string;
  x: number;
  y: number;
  active: boolean;
}> = ({ url, x, y, active }) => {
  const [rotation] = useState(randomIntFromInterval(-15, 15));
  if (url === '') return <></>;
  return (
    <S.ProjectMediaImg
      active={active}
      src={url}
      style={{
        transform: `translate(${x - 286 / 2}px,${
          y - 200 / 2
        }px) rotate(${rotation}deg)`,
      }}
    />
  );
};

const Item: React.FC<{
  children?: React.ReactNode;
  setActiveIndex: (idx: number) => void;
  index: number;
  url?: string;
}> = ({ children, setActiveIndex, index, url }) => (
  <S.ProjectLine
    onMouseEnter={() => setActiveIndex(index)}
    onMouseLeave={() => setActiveIndex(-1)}
    href={url ?? ''}
    target="_blank"
    rel="noopener noreferrer"
    aria-disabled={url === undefined ? 'true' : undefined}
  >
    {children}
    <S.ArrowIcon />
  </S.ProjectLine>
);

const projects: { title: string; mediaUrl: string; url?: string }[] = [
  {
    title: 'FLURRY FINANCE',
    mediaUrl: FlurryScreen,
    url: 'https://flurry.finance',
  },
  {
    title: 'C-TRADE',
    mediaUrl: CTradeScreen,
    url: 'https://c-trade.com',
  },
  {
    title: 'NEXTER TRAINING',
    mediaUrl: NexterScreen,
    url: 'https://www.nexter-group.fr/en/our-brand/nexter-training',
  },
  {
    title: 'CV',
    mediaUrl: '',
    url: '/Valentin_Van_Senior_Frontend_UIUX.pdf',
  },
  // {
  //   title: 'UNRELEASE DEFI PROJECT',
  //   mediaUrl:
  //     'https://yt3.ggpht.com/yti/APfAmoHGQNO3V4FROe8xB7Y3WB9LFekd0ND27rEA4VYG=s88-c-k-c0x00ffffff-no-rj-mo',
  // },
];

const Projects: React.FC<{ height: number }> = ({ height }) => {
  const { x, y } = useMousePosition();
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <S.Wrapper height={height}>
      <S.ProjectLines>
        {projects.map((p, index) => {
          return (
            <Item
              key={index}
              children={p.title}
              index={index}
              setActiveIndex={setActiveIndex}
              url={p.url}
            />
          );
        })}
      </S.ProjectLines>
      <S.ProjectMedia>
        {projects.map(({ mediaUrl }, index) => {
          const isActive = index === activeIndex;
          const xPos = isActive ? x : 0;
          const yPos = isActive ? y : 0;
          return (
            <>
              <MediaItem
                key={`${index}-media`}
                url={mediaUrl}
                x={xPos}
                y={yPos}
                active={isActive}
              />
            </>
          );
        })}
      </S.ProjectMedia>
    </S.Wrapper>
  );
};

export default Projects;
