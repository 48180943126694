import React from 'react';
import * as S from './App.styles';
import Home from '../Home/Home';
import { createTheme } from 'styled-breakpoints';
import { ThemeProvider } from 'styled-components';

const theme = createTheme({
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
  xxxl: '2000px',
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <S.GlobalStyles />
      <S.Wrapper>
        <Home />
      </S.Wrapper>
    </ThemeProvider>
  );
};

export default App;
