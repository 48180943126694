import styled from 'styled-components/macro';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: #00fff0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  overflow: hidden;
`;

export const TopPart = styled.div`
  position: relative;
  height: 64%;
  display: flex;
  flex-direction: column;
  ${down('md')} {
    height: 58%;
  }
  ${down('sm')} {
    height: 50%;
  }
`;
export const PortraitImg = styled.img`
  position: absolute;
  transform: translateX(-50%);
  width: auto;
  user-drag: none;
  user-select: none;
`;
export const PortraitImgBottom = styled(PortraitImg)<{ bottom: number }>`
  height: calc(50.25%);
  margin-top: -1px;
  bottom: -${({ bottom }) => bottom * 2}%;
  margin-left: 1px;
`;
export const PortraitImgTop = styled(PortraitImg)<{ top: number }>`
  top: -${({ top }) => top * 2}%;
  height: 50%;
`;

export const Subtitle = styled.h2`
  margin: 0;
  padding: 0;
  color: black;
  font-size: 5vh;
  text-align: center;
  letter-spacing: 20px;
  margin-left: 20px;
  ${down('md')} {
    letter-spacing: 10px;
  }
`;

export const Skills = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

export const Skill = styled.h3`
  margin: 0;
  padding: 0;
  color: black;
  font-size: 20px;
  text-align: center;
  ${down('md')} {
    font-size: 15px;
  }
`;

export const Dot = styled.div`
  background-color: black;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin: 0 15px;
  ${down('md')} {
    margin: 0 5px;
  }
`;

export const BottomPart = styled.div<{ opacity: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ opacity }) => 1 - opacity * 15};
`;

export const TestWrapper = styled.div<{ height: number }>`
  background-color: black;
  width: 100%;

  margin-top: ${({ height }) => -(height / 2) * 10}vh;
  height: ${({ height }) => height * 10}vh;
`;
