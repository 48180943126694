import styled from 'styled-components/macro';
import { down } from 'styled-breakpoints';
import { FaArrowRight } from 'react-icons/fa';

export const Wrapper = styled.div<{
  height: number;
}>`
  position: absolute;
  width: 100%;
  bottom: ${({ height }) => {
    const v = 62 - height / 1.5;
    if (v < 10) return 10;
    return v;
  }}%;
  top: ${({ height }) => {
    const v = 38 - height / 1.5;
    if (v < 10) return 10;
    return v;
  }}%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ProjectLines = styled.div`
  z-index: 1;
  width: 100%;
`;

export const ArrowIcon = styled(FaArrowRight)`
  margin-right: 20px;

  font-size: 3vw;
  transition: all 0.2s linear;
  ${down('md')} {
    font-size: 4vw;
  }
  ${down('sm')} {
    font-size: 5vw;
  }
`;

export const ProjectLine = styled.a`
  mix-blend-mode: difference;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    opacity: 0.2;
    ${ArrowIcon} {
      transform: rotate(-45deg);
    }
  }
  font-family: Helvetica;
  font-size: 5vw;
  line-height: 5vw;
  border-bottom: 5px solid black;
  width: calc(100% - 50px);
  padding: 25px 50px 0 50px;

  ${down('md')} {
    font-size: 8vw;
    line-height: 8vw;
    width: calc(100% - 20px);
    padding: 25px 20px 0 20px;
  }
  ${down('sm')} {
    font-size: 10vw;
    width: calc(100% - 10px);
    padding: 25px 10px 0 10px;
    line-height: 10vw;
  }
`;

export const ProjectMedia = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${down('sm')} {
    display: none;
  }
`;

export const ProjectMediaImg = styled.img<{ active: boolean }>`
  position: absolute;
  width: auto;
  height: auto;
  height: 200px;
  width: 286px;
  object-fit: contain;
  pointer-events: none;
  opacity: ${({ active }) => (active ? 1 : 0)};

  z-index: 2;
`;
